<template>
  <v-container fluid fill-height id="wholeCont" ref="wholeCont">
    <v-dialog v-if="$vuetify.breakpoint.name !== 'xl'" v-model="dialogState">
      <v-sheet>
        <news-card v-if="selectedHappening.temp !== 'temp'"
                   :id="selectedHappening.id"
                   :title="selectedHappening.title"
                   :title_en="selectedHappening.title_en"
                   :subtitle="selectedHappening.subtitle"
                   :subtitle_en="selectedHappening.subtitle_en"
                   :text="selectedHappening.text"
                   :text_en="selectedHappening.text_en"
                   :category="selectedHappening.category"
                   :publishedtime="selectedHappening.publishedtime"
                   :eventtime="selectedHappening.eventtime"
                   :location="selectedHappening.location"
                   :location_en="selectedHappening.location_en"
                   :form="selectedHappening.form"
                   :image="{...selectedHappening.image, bigimg: false}"
        ></news-card>
      </v-sheet>
    </v-dialog>
    <v-row class="flex-row-reverse" justify="center" align="center">
      <v-col :cols="12 - calCols" class="pb-0">
        <v-row justify="center" align="center">
          <v-btn icon @click="calenderButtonClicked(-1)"><v-icon>mdi-arrow-left-circle</v-icon></v-btn>
          <v-sheet width="15em" color="background" class="align-center">
            <v-menu :close-on-content-click="false" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="monthAndYear" :label="loadText(currentLang, 'VäljMånad')"
                              prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
              </template>
              <v-date-picker :locale="falenderLang" v-model="monthAndYear" type="month" no-title scrollable ></v-date-picker>
            </v-menu>
          </v-sheet>
          <v-btn icon @click="calenderButtonClicked(1)"><v-icon>mdi-arrow-right-circle</v-icon></v-btn>
          <v-col v-if="$vuetify.breakpoint.name === 'xl'" cols="12">
            <v-sheet class="overflow-auto" height="60vh" color="background">
              <news-card  height="60vh" v-if="selectedHappening.temp !== 'temp'"
                :id="selectedHappening.id"
                :title="selectedHappening.title"
                :title_en="selectedHappening.title_en"
                :subtitle="selectedHappening.subtitle"
                :subtitle_en="selectedHappening.subtitle_en"
                :text="selectedHappening.text"
                :text_en="selectedHappening.text_en"
                :category="selectedHappening.category"
                :publishedtime="selectedHappening.publishedtime"
                :eventtime="selectedHappening.eventtime"
                :location="selectedHappening.location"
                :location_en="selectedHappening.location_en"
                :form="selectedHappening.form"
                :image="{...selectedHappening.image, bigimg: false}"
              ></news-card>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="calCols">
        <v-sheet :height="calendarHeight">
          <v-calendar
            ref="Falender"
            color="primary"
            :locale="falenderLang"
            :type="calendarType"
            :start="startCalendar"
            :events="eventList"
            :event-color="getEventColor"
            :weekdays="weekdays"
            @click:event="eventClicked"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import textManager from '@/Texthanterare.js'
import NewsCard from '@/components/Newscard.vue'
import axios from 'axios'
// import axios from 'axios'

export default {
  name: 'Calendar',
  data: () => {
    const currentDate = new Date().toISOString().substr(0, 7)
    return {
      calendarHeight: '800px',
      dialogState: false,
      happenings: [],
      calendarMonthView: true,
      calendarTypes: ['month', 'week'],
      monthAndYear: currentDate,
      calendarType: 'month',
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      selectedHappening: {
        temp: 'temp'
      }
    }
  },
  components: {
    NewsCard
  },
  created: function () {
    axios
      .get('/api/event/all')
      .then((result) => {
        // result = result.data
        // console.log(result.data)

        // Ändra url beroende på om bild är uppladdad eller om länk
        for (let i = 0; i < result.data.length; i++) {
          const data = result.data[i]
          let image
          if (data.image.split('.').length === 2) {
            const imgname = data.image
            // "../assets/startsida.jpg"
            // `@/assets/${imgname}`
            image = {
              url: 'https://api.futf.se/api/images/' + imgname,
              bigimg: data.bigimg
            }
          } else {
            image = {
              url: data.image,
              bigimg: data.bigimg
            }
          }

          // TODO: should be handled in AddEvent.vue
          data.id = parseInt(data.id)
          const form = {
            id: data.form_id,
            opentime: data.form_opentime,
            closetime: data.form_closetime
          }
          data.image = image
          data.form = form
          result.data[i] = data
        }
        this.happenings = result.data
        // console.log(this.posts)
      })
      .catch((err) => {
        console.log(err)
      })
    window.addEventListener('resize', this.calendarHandler)
    this.calendarHeightUpdate()
  },
  computed: {
    eventList: function () {
      const theEvents = []
      // console.log('Selected: ' + this.selectedHappening)
      for (let i = 0; i < this.happenings.length; i++) {
        const post = this.happenings[i]
        // Iterate through events and reformat for Vuetify calendar
        if (post.category === 'event') {
          theEvents.push(this.convertGeneralEvent(post))
          // console.log(this.convertGeneralEvent(post).happening)
        } else if (post.category === 'application') {
          theEvents.push(this.convertApplication(post))
          // console.log(this.convertGeneralEvent(post).happening)
        }
      }
      return theEvents
    },
    currentLang: function () {
      return this.$store.getters.lang
    },
    startCalendar: function () {
      return this.monthAndYear + '-01'
    },
    calCols: function () {
      const currentBp = this.$vuetify.breakpoint.name
      if (currentBp === 'xl') { // Test if display is large enough
        return 9 // Side-by-side view
      }
      return 12 // Full width
    },
    falenderLang: function () {
      if (this.currentLang === 'swe') return 'sv'
      if (this.currentLang === 'eng') return 'en'
      return 'sv'
    }
  },
  methods: {
    calendarHandler (e) {
      // console.log('Inner Height: ' + window.innerHeight)
      this.calendarHeightUpdate()
    },
    thisIsPerfectDoNotRead: function () {
      let datepickerOffset
      // console.log('This Is Perfect')
      if (this.$vuetify.breakpoint.name === 'xl') {
        datepickerOffset = 60
      } else {
        datepickerOffset = 120
      }
      return window.innerHeight - datepickerOffset - 64 - 28 + 36 + 16
    },
    calendarHeightUpdate: function () {
      const imaginedHeight = this.thisIsPerfectDoNotRead()
      if (imaginedHeight >= 800) {
        // console.log('Setting height to imagined: ' + imaginedHeight)
        this.calendarHeight = imaginedHeight + 'px'
      } else {
        // console.log('Defaulting to height: 800px')
        this.calendarHeight = '800px'
      }
    },
    eventClicked (event) {
      this.selectedHappening = event.event.happening
      // console.log(window.innerHeight)
      if (this.$vuetify.breakpoint.name === 'xl') {
      } else {
        this.dialogState = true
      }
    },
    getEventColor (event) {
      return event.color
    },
    loadText: function (lang, key) {
      return textManager(lang, key)
    },
    convertGeneralEvent: function (happening) {
      // Check for event end time, default to start time otherwise
      var eventEnd
      try {
        eventEnd = happening.endtime
      } catch (e) {
        eventEnd = happening.eventtime.substr(0, 10)
        // console.log('endtime not found for event: ' + happening.title)
      }
      let eventColor = this.$vuetify.theme.themes.light.eventcolor
      if (this.$vuetify.theme.dark) {
        eventColor = this.$vuetify.theme.themes.dark.eventcolor
      }
      return {
        name: this.getHappeningTitle(happening),
        start: happening.eventtime.substr(0, 10),
        end: eventEnd,
        color: eventColor, // Blue for events
        timed: false,
        happening: happening
      }
    },
    convertApplication: function (happening) {
      let applicationColor = this.$vuetify.theme.themes.light.primary
      if (this.$vuetify.theme.dark) {
        applicationColor = this.$vuetify.theme.themes.dark.primary
      }
      return {
        name: this.getHappeningTitle(happening), // Possibly add string to mark as application (tex: "Sök: ")
        start: happening.form.opentime.substr(0, 10),
        end: happening.form.closetime.substr(0, 10),
        color: applicationColor, // Red for applications
        timed: false,
        happening: happening
      }
    },
    getHappeningTitle: function (happening) {
      if (happening.short_title === undefined) return happening.title
      return happening.short_title
    },
    calenderButtonClicked: function (dir) {
      const [year, month] = this.monthAndYear.split('-')
      if (dir === 1) {
        if (month !== '12') {
          const newMonth = parseInt(month) + 1
          const paddedMonth = newMonth.toString().padStart(2, '0') // pad with zeros so 2 -> 02
          this.monthAndYear = year + '-' + paddedMonth
        } else {
          const newYear = parseInt(year) + 1
          this.monthAndYear = newYear + '-' + '01'
        }
      } else if (dir === -1) {
        if (month !== '01') {
          const newMonth = parseInt(month) - 1
          const paddedMonth = newMonth.toString().padStart(2, '0') // pad with zeros so 2 -> 02
          this.monthAndYear = year + '-' + paddedMonth
        } else {
          const newYear = parseInt(year) - 1
          this.monthAndYear = newYear + '-' + '12'
        }
      } else {
        console.error('Only 1 and -1 are valid inputs to calenderButtonClicked!')
      }
    }
  }
}
</script>
